@use "@angular/material" as mat;
// @use './scss/custom-typograpthy.scss' as customTypography;

@include mat.core();
// @include mat.legacy-core();
// $custom-typography: mat.define-typography-config(
//   $font-family: 'Inter UI',
//   $headline-1: mat.define-typography-level(34px, 40px, 400),
//   $headline-2: mat.define-typography-level(45px, 48px, 600),
//   $headline-3: mat.define-typography-level(14px, 24px, 700),
//   $headline-4: mat.define-typography-level(16px, 20px, 400),
//   $headline-5: mat.define-typography-level(22px, 32px, 700, $font-family: 'Objektiv Mk2'),
//   $headline-6: mat.define-typography-level(20px, 32px, 700),
//   $subtitle-1: mat.define-typography-level(16px, 20px, 700),
//   $subtitle-2: mat.define-typography-level(14px, 20px, 700),
//   $body-1: mat.define-typography-level(16px, 24px, 400),
//   $body-2: mat.define-typography-level(16px, 24px, 700),
//   $caption: mat.define-typography-level(13px, 20px, 400),
//   $button: mat.define-typography-level(10px, 30px, 700),
// );
// @include mat-base-typography($custom-typography);
// @include mat-typography-hierarchy($custom-typography);
  // $font-family: 'NN Nouvelle Grotesk, sans-serif',
  // $font-family: 'Inter UI',
  // $font-weight: 300,

  //   $display-4: mat.define-typography-level(16px, 20px, 400),
//   $display-3: mat.define-typography-level(14px, 24px, 700),
//   $display-2: mat.define-typography-level(45px, 48px, 600),
//   $display-1: mat.define-typography-level(34px, 40px, 600),
  // $display-1: mat.define-typography-level(32px, 36px, 400, $font-family: 'GT Flexa'),
  // $display-2: mat.define-typography-level(28px, 32px, 600, $font-family: 'GT Flexa'),
  // $display-3: mat.define-typography-level(24px, 28px, 600, $font-family: 'GT Flexa'),
  // $display-4: mat.define-typography-level(22px, 26px, 400, $font-family: 'GT Flexa'),
$custom-typography: mat.define-typography-config(
  $font-family: 'Inter UI',
  $headline-1: mat.define-typography-level(32px, 36px, 400, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $headline-2: mat.define-typography-level(28px, 32px, 500, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $headline-3: mat.define-typography-level(24px, 28px, 500, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $headline-4: mat.define-typography-level(22px, 26px, 500, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $headline-5: mat.define-typography-level(24px, 26px, 700, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $headline-6: mat.define-typography-level(18px, 32px, 700, $font-family: 'NN Nouvelle Grotesk, sans-serif'),
  $subtitle-1: mat.define-typography-level(16px, 20px, 700),
  $subtitle-2: mat.define-typography-level(14px, 20px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 18px, 500),
  $caption: mat.define-typography-level(13px, 20px, 400),
  $button: mat.define-typography-level(14px, 36px, 500, $font-family: 'GT Flexa'),
);

@include mat.typography-hierarchy($custom-typography);


$bg-primary-palette: (
  50: #e4e7ed,
  100: #bcc2d4,
  200: #919cb7,
  300: #68769a,
  400: #495a87,
  500: #294076,
  600: #243a6e,
  700: #1c3163,
  800: #152857,
  900: #0b1940,
  A100: #809aff,
  A200: #4d72ff,
  A400: #1a49ff,
  A700: #0035ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$bg-secondary-palette: (
  50: #eaebff,
  100: #c9cbff,
  200: #a3aaff,
  300: #7887ff,
  400: #5569ff,
  500: #2c4bff,
  600: #2742f3,
  700: #1935e6,
  800: #0327db,
  900: #0000cb,
  A100: ffffff,
  A200: f8f9ff,
  A400: c5c9ff,
  A700: acb1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$bg-warn-palette: (
  50: #fceae8,
  100: #f6cbc6,
  200: #f1a9a0,
  300: #eb867a,
  400: #e66c5e,
  500: #e25241,
  600: #df4b3b,
  700: #da4132,
  800: #d6382a,
  900: #cf281c,
  A100: #ffffff,
  A200: #ffd4d1,
  A400: #ffa39e,
  A700: #ff8b85,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$web-primary: mat.define-palette($bg-primary-palette);
$web-accent: mat.define-palette($bg-secondary-palette);
$web-warn: mat.define-palette($bg-warn-palette);

// Create the theme object. A theme consists of configurations for individual
$web-theme: mat.define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
      warn: $web-warn,
    ),
    typography: $custom-typography,
  )
);
// @include mat.typography-hierarchy($custom-typography);

// @include mat.core($custom-typography);
@include mat.all-component-themes($web-theme);
// @include mat.all-legacy-component-themes($web-theme);

/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './scss/mat-buttons.scss';
@import './scss/mat-dialog.scss';
@import './scss/mat-progress-bar.scss';
@import './scss/mat-input.scss';
@import './scss/mat-table.scss';
@import './scss/mat-card.scss';
@import './scss/mat-drop-cdk.scss';
@import './scss/mat-tooltip.scss';
@import './scss/fonts.scss';
@import './scss/side-nav.scss';
@import './scss/custom-typograpthy.scss';
@import './scss/highlightjs.scss';
@import './scss/ngx-material-timepicker.scss';
@import './scss/ngx-file-drop.scss';
@import './scss/mat-expansion-panel.scss';

@import '@angular/cdk/overlay-prebuilt.css';

@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';

/* Core Grid CSS */

/* Quartz Theme Specific CSS */

// ag grid

// @include ag.grid-styles((
//   theme: custom-angular,
//   extend-theme: alpine,

//   odd-row-background-color: #f2f8f2,
//   row-border-color: transparent,
//   row-hover-color: null,
//   checkbox-checked-color: #2661ad,
//   range-selection-border-color: #ff00b1,
//   range-selection-background-color: #03305633,
// ));

// .ag-theme-custom-angular  .ag-menu-option-active {
//   background-color: #2661ad;
//   color: white;
// };


html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;


}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  -webkit-overflow-scrolling: touch;
}

.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 !important;
}



.hub-overview-card .mat-mdc-card {
  border-radius: 8px;
  width: 272px;
  height: 232px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
}

// .hub-overview-card .mat-mdc-progress-bar {
//   border-radius: 8px;
//   height: 12px;
// }

// .hub-overview-card .mat-mdc-progress-bar-fill::after {
//   background-color: #5569ff;
// }
.filesUploader.mat-mdc-expansion-panel-header:not([aria-disabled="true"]) {
  cursor: pointer;
  background-color: #212121 !important;
}

.no-bottom-space .mat-mdc-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

.mat-mdc-icon {
  vertical-align: middle;
}
/** add and remove member **/
.participant-title {
  color: #294076 !important;
}

.phone-input:focus-visible {
  outline: #2c4bff solid 2px;
}






// .table-overlay {
//   position: absolute;
//   top: 0;
//   left: 0px;
//   width: 100%;
//   height: calc(100vh - 130px);
//   background-color: #0327db;
// }
.popup {
  opacity: 0;
  width: 800px;
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 5px 5px rgba(146, 145, 145, 0.2);
  background-color: #fff;
  right: 20px;
  top: 30px;
  height: 600px;
  z-index: 99999;
}
.record-popup-custom {
  .mat-mdc-form-field-flex {
    background-color: transparent !important;
  }
}
.example-form-field {
  .mat-mdc-input-element {
    padding: 10px !important;
  }
}
.table-form {
  overflow-x: scroll;
  .data-explorer-scrol {
    min-width: 1000px;
    .table-data {
      padding: 10px !important;
      .mat-mdc-chip {
        font-size: 12px !important;
      }
      .mat-mdc-standard-chip.mat-mdc-chip-with-trailing-icon {
        padding-left: 8px;
      }
    }
  }
}

.menu-search {
  mat-form-field {
    padding-bottom: 0px !important;
  }
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 10px !important;
  }
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    margin: 0.25em 0;
    padding-bottom: 0px;
  }
  .mat-mdc-chip.mat-mdc-standard-chip {
    background-color: #db4437;
    color: #fff;
  }
}
.add-new-items {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}


h1, h2, h3, h4, h5, h6 {
   margin: 0!important;
}


//  Typography


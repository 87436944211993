.mat-mdc-dialog-container {
  background: #f8f8f8 !important;
  box-shadow: 0px 1px 24px rgb(26 26 26 / 20%) !important;
  border-radius: 8px !important;
  position: relative;
}

.dialog-container-custom .mat-mdc-dialog-container {
  padding: 0px !important;
}
.dialog-container-custom .mat-mdc-dialog-title {
  padding: 0px !important;
  margin: 0px !important;
}

.bg-white > .mat-mdc-dialog-container {
  background: white !important;
}

.mat-mdc-dialog-title {
  font-family: Objektiv Mk2 !important;
  font-style: normal;
  font-weight: bold !important;
  color: #294076;
  line-height: 32px !important;
  font-size: 22px !important;
  margin: 0px 0px 24px 0px !important;
}

[mat-dialog-subtitle] {
  // font-family: Objektiv Mk2 !important;
  font-family: Inter UI !important;
  font-style: normal;
  font-weight: 500 !important;
  color: #636363 !important;
  line-height: 20px !important;
  font-size: 14px !important;
}

.mat-mdc-typography h3 {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

// .mat-mdc-dialog-actions .mat-mdc-button-base {
//   background-color: white !important;
//   border-radius: 8px !important;
//   color: #2c4bff !important;
//   border: 1px solid rgba(228, 231, 237, 0.6) !important;
// }

// .mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
//   background-color: #2c4bff !important;
//   border-radius: 8px !important;
//   color: white !important;
// }

.mat-mdc-dialog-container > * > {
  [mat-dialog-close] {
    float: right;
    .mat-mdc-icon {
      color: #294076;
    }
  }
}

.mat-mdc-dialog-container {
  bg-dialog-stepper {
    top: 3.5rem;
    position: absolute;
    left: 50%;
    display: block;
    transform: translate(-50%, 0);
  }
}

.mat-mdc-dialog-container .zero-top-offset {
  bg-dialog-stepper {
    top: 20px;
  }
}

@media (max-width: 640px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    // min-height: 100vh !important;
  }

  .mat-mdc-dialog-container {
    padding: 1rem !important;
    width: 100vw !important;
  }

  .mat-mdc-dialog-content.content-full-height {
  max-height: 90vh

}
}

.mat-mdc-dialog-content.content-full-height {
  max-height: 75vh

}

@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}






@font-face {
  font-family: 'GT Flexa';
  src: url('../fonts/gt-flexa/GT-Flexa-Standard-Lazer.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}


// Nouvelle Grotesk
@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('../fonts/NNNouvelleGrotesk/NNNouvelleGrotesk-Normal.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('../fonts/NNNouvelleGrotesk/NNNouvelleGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('../fonts/NNNouvelleGrotesk/NNNouvelleGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;

}



// inter
@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-BlackItalic.eot');
    src: url('../fonts/interUI/InterUI-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-BlackItalic.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-BlackItalic.woff') format('woff'),
        url('../fonts/interUI/InterUI-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Objektiv Mk2';
    src: url('../fonts/ObjektivMk/Objektiv-Mk2-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Bold.eot');
    src: url('../fonts/interUI/InterUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-Bold.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-Bold.woff') format('woff'),
        url('../fonts/interUI/InterUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Bold.eot');
    src: url('../fonts/interUI/InterUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-BoldItalic.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-BoldItalic.woff') format('woff'),
        url('../fonts/interUI/InterUI-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Black.eot');
    src: url('../fonts/interUI/InterUI-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-Black.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-Black.woff') format('woff'),
        url('../fonts/interUI/InterUI-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Medium.eot');
    src: url('../fonts/interUI/InterUI-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-Medium.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-Medium.woff') format('woff'),
        url('../fonts/interUI/InterUI-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Italic.eot');
    src: url('../fonts/interUI/InterUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-Italic.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-Italic.woff') format('woff'),
        url('../fonts/interUI/InterUI-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-MediumItalic.eot');
    src: url('../fonts/interUI/InterUI-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-MediumItalic.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-MediumItalic.woff') format('woff'),
        url('../fonts/interUI/InterUI-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../fonts/interUI/InterUI-Regular.eot');
    src: url('../fonts/interUI/InterUI-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interUI/InterUI-Regular.woff2') format('woff2'),
        url('../fonts/interUI/InterUI-Regular.woff') format('woff'),
        url('../fonts/interUI/InterUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.mat-mdc-cell {
  padding: 4px !important;
  color: #424242 !important;
  border-right: 1px solid #E0E0E0;
}
.mat-mdc-cell.entitys {
  border-right: none;
}
.mat-mdc-cell.entitys.last {
  border-right: 1px solid #E0E0E0;
}
td.mat-mdc-cell:last-child {
  border-right: none;
}

// make cells grow
th.mat-header-cell,
td.mat-cell {
  flex-grow: 1;
  white-space: nowrap;
}

.mat-mdc-header-cell {
  padding: 12px 20px 12px 20px !important;
  color: #7F7F7F !important;
  background-color: white !important;
  line-height: 1.5 !important;
  border-right: 1px solid #E0E0E0 !important;
}

.entitys.mat-mdc-header-cell {
  padding: 12px 20px 12px 20px !important;
  // color: #677498 !important;
  background-color: white !important;
  line-height: 1.5 !important;
  border-right: none !important;
  border-top: 2px solid #5772ee !important;
}
.entitys.last.mat-mdc-header-cell {
  border-right: 1px solid #E0E0E0 !important;
}

.advancing-column.mat-mdc-header-cell {
  padding: 12px 20px 12px 20px !important;
  // color: #677498 !important;
  background-color: white !important;
  line-height: 1.5 !important;
  border-right: 1px solid #E0E0E0 !important;
  border-top: 2px solid #EAB124 !important;
}

.bg-yellow-200.mat-mdc-header-cell {
  background-color: rgba(253, 230, 138, 1) !important;
}

th.mat-mdc-header-cell:last-child {
  border-right: none;
}


mat-row {
  background-color: transparent !important;
}

.mat-mdc-table {
  border-radius: 8px !important;
  border: 1px solid #E0E0E0 !important;
  display: inline-block !important;
  overflow: auto !important;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid #E0E0E0 !important;
  box-shadow: 2px 0px 1px 0px rgba(200,200,200,1);
}

.mat-mdc-footer-row > .mat-mdc-table-sticky-border-elem-left {
  box-shadow: none;

}


// @use "@angular/material" as mat;

@import './fonts.scss';

// @font-face {
//     font-family: 'ObjektivMk';
//     src: url('../fonts/ObjektivMk/ObjektivMk1_XBd.ttf');
//   }

// $custom-typography: mat.define-typography-config(
//   $font-family: 'Inter UI',
//   $display-4: mat.define-typography-level(16px, 20px, 400),
//   $display-3: mat.define-typography-level(14px, 24px, 700),
//   $display-2: mat.define-typography-level(45px, 48px, 600),
//   $display-1: mat.define-typography-level(34px, 40px, 600),
//   $headline: mat.define-typography-level(22px, 32px, 700, $font-family: 'Objektiv Mk2'),
//   $title: mat.define-typography-level(20px, 32px, 700),
//   $subheading-2: mat.define-typography-level(16px, 20px, 700),
//   $subheading-1: mat.define-typography-level(14px, 20px, 700),
//   $body-2: mat.define-typography-level(16px, 24px, 700),
//   $body-1: mat.define-typography-level(16px, 24px, 400),
//   $caption: mat.define-typography-level(13px, 20px, 400),
//   $button: mat.define-typography-level(10px, 30px, 700),
// );

// @include mat-typography-hierarchy($custom-typography);

// @include mat.core($custom-typography);

// $custom-typography: mat-typography-config(
//   $font-family: 'Inter UI',
//   $display-4: mat-typography-level(16px, 20px, 400),
//   $display-3: mat-typography-level(14px, 24px, 700),
//   $display-2: mat-typography-level(45px, 48px, 600),
//   $display-1: mat-typography-level(34px, 40px, 600),
//   $headline: mat-typography-level(22px, 32px, 700, $font-family: 'Objektiv Mk2'),
//   $title: mat-typography-level(20px, 32px, 700),
//   $subheading-2: mat-typography-level(16px, 20px, 700),
//   $subheading-1: mat-typography-level(14px, 20px, 700),
//   $body-2: mat-typography-level(16px, 24px, 700),
//   $body-1: mat-typography-level(16px, 24px, 400),
//   $caption: mat-typography-level(13px, 20px, 400),
//   $button: mat-typography-level(10px, 30px, 700),
// );
// @include mat-base-typography($custom-typography);

.mat-mdc-body-3 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}
.mat-mdc-overline {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.mat-mdc-notes {
  font-size: 13px;
  line-height: 24px;
  color: rgb(0 0 0 / 87%);
  font-weight: 400;
}
.mat-mdc-caption-heavy {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.mat-typography .mat-h1,
.mat-typography .mat-headline-1,
.mat-typography h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

.mat-typography .mat-h2,
.mat-typography .mat-headline-2,
.mat-typography h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

.mat-typography .mat-h3,
.mat-typography .mat-headline-3,
.mat-typography h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

.mat-typography .mat-h4,
.mat-typography .mat-headline-4,
.mat-typography h4 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

.mat-typography .mat-h5,
.mat-typography .mat-headline-5,
.mat-typography h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

.mat-typography .mat-h6,
.mat-typography .mat-headline-6,
.mat-typography h6 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  font-family: 'GT Flexa';
  margin: 0 0 16px;
}

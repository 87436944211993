@tailwind base;


.mat-mdc-expansion-panel-header.todo {
  // &:hover {
  //     background: #1c3163 !important;
  // }
  background: #E4E7ED !important;
}

.mat-mdc-expansion-panel-header.todo.mat-mdc-expanded {
  background: #1C3163 !important;
}


.mat-mdc-expansion-panel-header-title.todo {
  flex-grow: initial;
  .mat-mdc-expansion-indicator::after {
    border-color: black !important;

  }
}
.mat-mdc-expansion-panel.todo {
  .mat-mdc-expansion-indicator::after {
    border-color: black !important;
  }
}

.mat-mdc-expansion-panel.todo.mat-mdc-expanded {
  .mat-mdc-expansion-indicator::after {
    border-color: #ffffff !important;
  }
}
.mat-mdc-expansion-panel-header-title.todo.mat-mdc-expanded {
  flex-grow: initial;
  color: #ffffff !important;
}


.mat-mdc-expansion-panel-body {
  background: #E4E7ED !important;
}

// @use "@angular/material" as mat;

*:not(.mat-mdc-simple-snackbar-action) > {
  .mat-mdc-button,
  .mat-mdc-flat-button,
  .mat-mdc-stroked-button {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 8px !important;
  }
}

.mat-mdc-icon-button {
  border-radius: 4px !important;
}


/* Base styles for MDC buttons */
.mdc-button {
  /* Adjust padding, border-radius, etc., as needed */
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-radius: 8px !important;
}
.outer-side-bar-max {
  width: calc(100% - 60px);
}

.outer-side-bar-min {
  width: calc(100% - 260px) !important;
}
.cdk-overlay-pane .side-nav-setting-container  h6 {
  font-size: 9px !important;
}

.cdk-overlay-pane .side-nav-setting-container {
  background: rgb(248, 248, 248);
}

.cdk-overlay-pane .side-nav-setting-container  li {
  font-size: 12px !important;
  padding: 10px 0px;
  color: #294076;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.expandable_icon {
  right: -12px;
}
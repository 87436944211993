.ngx-file-drop__drop-zone {
  border: none !important;
  height: unset !important;
  margin: unset !important;
  border-radius: unset !important;
}
.ngx-file-drop__content {
   align-items:  unset !important;
    color:  unset !important;
    display:  unset !important;
    height: unset !important;
    justify-content: unset !important;

}

.ngx-file-drop__drop-zone--over {
  background-color: unset !important;
}

// New styles

// Adjustments for MDC Form Fields
.mat-mdc-form-field {
  margin-top: 0.625rem;
  margin-bottom: 0.5rem;
  max-height: calc(3.5rem + margin-top + margin-bottom);
  // display: flex;
  // align-items: center;

  &.m-p-0-input .mat-mdc-text-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 0 !important;
  }

  &.mt-2-p-0-input .mat-mdc-text-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 8px !important;
  }

}

.mat-mdc-form-field {
  height: 56px;
}


.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: unset;
}

.mat-mdc-form-field .mat-mdc-form-field-flex,
.mat-mdc-form-field .mat-mdc-form-field-infix {
  height: 100%;
}

.mat-mdc-form-field .mat-mdc-input-element {
  height: 100%;
  box-sizing: border-box;
}


.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 0px !important;
}

.mat-mdc-form-field-infix {
  height: 56px;
}

// .mat-mdc-form-field-infix {
//   border-top: 0;
//   padding-top: 0;
//   padding-bottom: 0;
// }


// Adjust text field appearance
.mdc-text-field--outlined {
  // Default state
  // background-color: #eff1f4;
  background-color: #eff1f4bd;
  border-radius: 8px !important;
  border: 1px solid #e9ebf0;
  // overflow: hidden;
  max-height: 3.5rem;

}

.limited-textarea {
  max-height: calc(1.5em * 5 + 16px) !important; /* Adjust according to line height and padding */
  overflow-y: auto !important;
}
// TODO: temporary, move to chatbot styles later
.chatbot-container {
  max-height: 100vh;
}

#chatbotFormField {
  display: flex;
  align-items: flex-end;

  max-height: 100vh;
  overflow: hidden;
  border-radius: 0.5rem;
  min-height: 3.5rem;
  // position: fixed;
  // bottom: 0;
  flex-direction: column-reverse;
  max-width: 96%;
}

#chatbotFormField textarea {
  resize: none;
  width: 84%;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  box-shadow: none;
  padding: 0.5rem !important;
  overflow: hidden;
  min-height: 3rem;
  margin-right: auto;
}


#chatbotFormField button {
  margin-left: 0.5rem;
  color: var(--tw-text-opacity, #6b7280); /* Tailwind text-secondary-500 */
}

.no-max-height .mdc-text-field--outlined {
  max-height: none !important;
}

.no-max-height-textarea {
  max-height: none !important;
}

// Focused state
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline {
  border-color: #2c4bff;
  border-width: 2px;
  border-radius: 8px;
}

// Invalid state
.ng-touched.ng-invalid .mdc-line-ripple {
  border-color: #e25241 !important;
  background-color: #fbefef;
}

// Error state
.mdc-text-field--outlined.ng-touched.ng-invalid .mdc-notched-outline {
  border-color: #e25241;
  background-color: #fbefef;
}

// Valid state
.mdc-text-field--outlined.ng-touched.ng-valid:not(.mdc-text-field--focused) .mdc-notched-outline {
  border-color: #e9ebf0;
  background-color: white;
}

// Label adjustments
// .mdc-floating-label {
//   margin-left: -0.75rem !important;
// }



// Text field without a top border and adjusted padding
.mdc-text-field--outlined .mdc-text-field__input {
  border-top: 0 !important;
  // padding-top: 1.125em !important;
  // padding-bottom: 0.875em !important;
}

// Adjust label position
// .mdc-floating-label {
//   margin-left: -0.75rem !important;
// }

mat-form-field.mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1.0;
}
.mat-mdc-form-field .mat-mdc-floating-label::after {
  content: none;
}



// Old styles
// Set input height and remove top space from floating label
// .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
//   border-top: 0 !important;
//   padding-top: 1.125em !important;
//   padding-bottom: 0.875em !important;
// }
// .mat-mdc-form-field {
//   margin-top: 0.625rem;
//   margin-bottom: 0.5rem;
// }

// .mat-mdc-form-field.m-p-0-input {
//   .mat-mdc-form-field-wrapper {
//     margin: 0 !important;
//     padding: 0 !important;
//   }
//   padding-bottom: 0 !important;
//   margin: 0 !important;
// }

// .mat-mdc-form-field.mt-2-p-0-input {
//   .mat-mdc-form-field-wrapper {
//     margin: 0 !important;
//     padding: 0 !important;
//   }
//   padding-bottom: 0 !important;
//   margin: 8px 0 0 0 !important;
// }

// Set text padding within input
.mat-mdc-form-field-outline .mat-mdc-form-field-flex {
  padding-left: 1em !important;
}

.icon-select .mat-mdc-form-field-flex {
  height: 50px;
}


// // Default State
// .mdc-text-field--outlined {
// // .mat-mdc-text-field-outlined {
//   background-color: #eff1f4;
//   border-radius: 8px !important;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #e9ebf0;
// }

// Hover State
// remove unused border outlines
.mat-mdc-form-field-outline-start {
  display: none !important;
}
.mat-mdc-form-field-outline-gap {
  display: none !important;
}
.mat-mdc-form-field-outline-end {
  display: none !important;
}

// Move label to the the left
.mat-mdc-form-field-appearance-outline {
  .mat-mdc-form-field-label-wrapper {
    margin-left: -0.75rem !important;
  }
}

// Set default color on error, so label it's not red on error state
.mat-mdc-form-field-appearance-outline.ng-touched.ng-invalid {
  .mat-mdc-form-field-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

// Mat error to left and closer to input
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper {
  // padding-left: 20px !important;
  padding-left: 0 !important;
  margin-top: 4px !important;
}

// remove * for required
.mat-mdc-placeholder-required {
  display: none;
}

// add (optional) for not required
.mat-mdc-form-field-appearance-outline:not(.no-optional) {
  .mat-mdc-input-element:not([required]),
  .mat-mdc-select:not(.mat-mdc-select-required) {
    ~ .mat-mdc-form-field-label-wrapper mat-label:after {
      padding-left: 0.5rem;
      content: '(optional)';
    }
  }
}



#answer-wrapper .mdc-text-field--outlined  {
  max-height: none !important;
  height: auto !important;
  resize: vertical;
  overflow: auto;
  margin-bottom: 0 !important;

  background-color: transparent !important;
  border-radius: 4px !important;
  // border: 1px solid #ccc !important;
}

#answer-wrapper.mat-mdc-form-field {
  height: unset !important;
  max-height: none !important;
  min-height: 12rem;
  margin-bottom: 0 !important;
}

#answer-wrapper textarea  {
  min-height: 10rem;

  // max-height: none !important;
  // height: auto !important;
  // resize: vertical;
  // overflow: auto;

  // background-color: transparent !important;
  // border-radius: 4px !important;
  // // border: 1px solid #ccc !important;
}


// Move icons in inputs lower and more to the sides
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix {
  top: 0.45em !important;
}
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix {
  left: -0.2em !important;
}
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix {
  left: 0.5em !important;
}
.mat-mdc-form-field-appearance-outline .mat-mdc-select-arrow-wrapper {
  transform: unset !important;
}

.in-button-dropdown div.mat-mdc-select-arrow-wrapper {
  display: none;
}

.in-button-dropdown.mat-mdc-select {
  display: inline;
}

// Error icon in the error message
[ngx-mat-errors] {
  position: relative;
}
[ngx-mat-errors]:before {
  font-family: 'Material Icons';
  content: '\e000';
  position: absolute;
  top: -1px;
  left: -18px;
  font-size: 14px;
}

.rounded-checkbox {
  & .mat-mdc-checkbox-background,
  .mat-mdc-checkbox-frame {
    border-radius: 70% !important;
  }
}

.time-select .mat-mdc-menu-content {
  max-height: 220px;
}
